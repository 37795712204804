import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import styles from "./styles.module.scss";
// SVG
import arrowIconOrange from '../../images/svg-icons/arrow-bright-orange.svg'

const Dentist = ({  dentist, icon = null }) => {

	const {
		id,
		title,
		link,
		ACFDentist
	} = dentist;

  	return (
		<Link key={id} to={link} className={styles.dentist}>
			{ ACFDentist && ACFDentist.headshot ? <Img fluid={ACFDentist.headshot.localFile.childImageSharp.fluid} className={styles.image} alt={title} loading={"eager"} /> : <div className={styles.noimage}></div> }
			<h3 className={styles.title}>{title}</h3>
			<span className={styles.view}>View profile { icon && <img src={arrowIconOrange} loading="lazy" alt="" /> }</span>
		</Link>
	);
}

export default Dentist;
