import React, { useState } from "react";
import { Link } from "gatsby";
import FixedImage from "../fixedImage"
import Img from "gatsby-image";
import styles from "./styles.module.scss";

const Dentists = ({  dentists, icon = null, arrowLightGray }) => {

	const [ currentDentist, setCurrentDentist ] = useState(0);
	const [ dentistsMax ] = useState(dentists.length);

	const previousDentist = () => {
		if( currentDentist === 0 ){
			setCurrentDentist(dentistsMax - 1);
		} else {
			setCurrentDentist(currentDentist - 1)
		}
	}

	const nextDentist = () => {
		if( currentDentist === (dentistsMax - 1) ){
			setCurrentDentist(0);
		} else {
			setCurrentDentist(currentDentist + 1)
		}
	}

  	return (
		<div className={styles.Dentists}>
			<span className={styles.heading}>Meet our dentists.</span>
			{ dentists && dentists.map( (dentist, index) => {

				const {
					id,
					ACFDentist,
					title,
					link
				} = dentist;
				
				return (
					<Link key={id} to={link} className={currentDentist === index ? styles.dentist : styles.hiddendentist}>
						<div className={styles.imagecontainer}>
							<button className={styles.previous} onClick={(event)=>{ event.preventDefault(); previousDentist(); }} type="button" aria-label="Show previous dentist"><FixedImage image={arrowLightGray} passedClass={styles.icon} /></button>
							<button className={styles.next} onClick={(event)=>{ event.preventDefault(); nextDentist(); }} type="button" aria-label="Show next dentist"><FixedImage image={arrowLightGray} passedClass={styles.icon} /></button>
							{ ACFDentist && ACFDentist.headshot ? <Img fluid={ACFDentist.headshot.localFile.childImageSharp.fluid} className={styles.image} alt={title} loading={"eager"} /> : <div className={styles.noimage}></div> }
						</div>
						<span className={styles.title} dangerouslySetInnerHTML={{__html: title}}></span>
						<span className={styles.view}>View profile { icon && <FixedImage image={icon} /> }</span>
					</Link>
				)
			})}
			<div className={styles.dentistnav}>
				{ dentists && dentists.map( (dentist, index) => {
					return <button key={`dentistnav-${dentist.id}`} className={currentDentist === index ? styles.activebullet : styles.bullet} type="button" aria-label={`View ${dentist.title}`} onClick={(event)=>{ setCurrentDentist(index); }} ></button>
				})}
			</div>
		</div>
	);
}

export default Dentists;
