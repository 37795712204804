const prepareSchemaHours = (hours) => {
	let formattedHours = [];

	if( hours ){

		hours.forEach((day) => {
			if (day.isOpen === 0) {
				return;
			}

			const dayOfWeek = getDay(day.day);

			if (dayOfWeek === null) {
				return;
			}

			day.workingHours.forEach((hours) => {
				formattedHours.push(`"${dayOfWeek} ${hours.startHour}-${hours.endHour}"`);
			});

		});
	
	}

	return formattedHours;
};

const getDay = (day) => {
	switch (day) {
		case 0:
			return 'Mo';
		case 1:
			return 'Tu';
		case 2:
			return 'We';
		case 3:
			return 'Th';
		case 4:
			return 'Fr';
		case 5:
			return 'Sa';
		case 6:
			return 'Su';
		default:
			break;
	}
};

export default prepareSchemaHours;
